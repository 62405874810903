import React, { useEffect, useState } from 'react';

const ContentAnimateur = ({content})=>{
  return (<div className="post post-over-content col-md-6">
  <div className="details clearfix">
    <h4 className="post-title"><a href="#/" alt={content.fullname}>{content.fullname}</a></h4>
  </div>
  <a href="#/">
    <div className="thumb rounded">
      <div className="inner">
        <img style={{maxHeight: '290px', minHeight: '290px'}} src={content.media_profil} alt={content.fullname} />
      </div>
    </div>
  </a>
</div>);
}


export default ContentAnimateur;
