import React, { useEffect, useState } from 'react';
import Preloader from './../components/Preloader';
import Breadcrumb from '../components/Breadcrumb';

const Contact = ()=>{
  const [isLoad, setIsload] = useState(true);
  if(!isLoad){ return <Preloader /> }

  return (<section className="main-content">
    
  <div className="container-xl">  
  <Breadcrumb title={"Contact"} />
    <div className="row">
        <div className="col-md-4">
            <div className="contact-item bordered rounded d-flex align-items-center">
                <span className="icon"><i className="fas fa-phone"></i></span>
                
                <div className="details">
                    <h3 className="mb-0 mt-0">Phone</h3>
                    <p className="mb-0"><a href="tel:+50940128484">+509 4012 8484</a></p>
                </div>
            </div>
            <div className="spacer d-md-none d-lg-none" data-height="30"></div>
        </div>

        <div className="col-md-4">
            <div className="contact-item bordered rounded d-flex align-items-center">
                <span className="icon"><i className="fas fa-envelope-open-text"></i></span>
                <div className="details">
                    <h3 className="mb-0 mt-0">E-Mail</h3>
                    <p className="mb-0"><a href="mailto:info@rezistansfm.com?subject=Contact">info@RezistansFM.com</a></p>
                </div>
            </div>
            <div className="spacer d-md-none d-lg-none" data-height="30"></div>
        </div>

        <div className="col-md-4">
            <div className="contact-item bordered rounded d-flex align-items-center">
                <span className="icon"><i className="fas fa-map-marked-alt"></i></span>
                <div className="details">
                    <h3 className="mb-0 mt-0">Location</h3>
                    <p className="mb-0"><a target="_blank" href="https://goo.gl/maps/PHuyxuMe4g8wrt7v5" rel="noreferrer">Miragoane, d’Haïti</a></p>
                </div>
            </div>
        </div>

    </div>
    <div className="spacer" data-height="50"></div>
    <div className="row rounded bordered p-2">
    <form id="contact-form" className="contact-form" method="post">
                
                <div className="messages"></div>
                
                <div className="row">
                    <div className="column col-md-6">
                        <div className="form-group">
                            <input type="text" className="form-control" name="InputName" id="InputName" placeholder="Nom complet" required="required" data-error="Name is required." />
                            <div className="help-block with-errors"></div>
                        </div>
                    </div>
                    
                    <div className="column col-md-6">
                        <div className="form-group">
                            <input type="email" className="form-control" id="InputEmail" name="InputEmail" placeholder="Address e-mail" required="required" data-error="Email is required." />
                            <div className="help-block with-errors"></div>
                        </div>
                    </div>

                    <div className="column col-md-12">
                        <div className="form-group">
                            <input type="text" className="form-control" id="InputSubject" name="InputSubject" placeholder="Sujet" required="required" data-error="Subject is required." />
                            <div className="help-block with-errors"></div>
                        </div>
                    </div>
            
                    <div className="column col-md-12">
                        <div className="form-group">
                            <textarea name="InputMessage" id="InputMessage" className="form-control" rows="4" placeholder="Votre message ici..." required="required" data-error="Message is required."></textarea>
                            <div className="help-block with-errors"></div>
                        </div>
                    </div>
                </div>

                <button type="submit" name="submit" id="submit" value="Submit" className="btn btn-default">Envoyer Message</button>

            </form>
    </div>
    
  </div>
</section>);
}


export default Contact;
