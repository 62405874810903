import React, { useEffect, useState } from 'react';
import Breadcrumb from '../components/Breadcrumb';
import DateEvent from '../components/DateEvent';
import SideBar from '../components/SideBar';
import dataList from './../data/data_programmation';
import Wave from './../images/wave.svg'

const Programmation = ()=>{
  const [] = useState();
  return (<section className="main-content">
   
  <div className="container-xl">
    <Breadcrumb title={"Programmation"} />
    <div className="row gy-4">
      <div className="col-lg-8 " >
        <hr className="my-4" />
        <div className="container-xl">
          <div className="row">
            <div className="col-lg-4 col-sm-12">
              <div className="list-group" id="list-tab" role="tablist">
                <a className="list-group-item list-group-item-action active" id="list-dimanche-list" data-bs-toggle="list" href="#list-dimanche" role="tab" aria-controls="list-dimanche">Dimanche</a>
                <a className="list-group-item list-group-item-action" id="list-lundi-list" data-bs-toggle="list" href="#list-lundi" role="tab" aria-controls="list-lundi">Lundi</a>
                <a className="list-group-item list-group-item-action" id="list-mardi-list" data-bs-toggle="list" href="#list-mardi" role="tab" aria-controls="list-mardi">Mardi</a>
                <a className="list-group-item list-group-item-action" id="list-mercredi-list" data-bs-toggle="list" href="#list-mercredi" role="tab" aria-controls="list-mercredi">Mercredi</a>
                <a className="list-group-item list-group-item-action" id="list-jeudi-list" data-bs-toggle="list" href="#list-jeudi" role="tab" aria-controls="list-jeudi">Jeudi</a>
                <a className="list-group-item list-group-item-action" id="list-vendredi-list" data-bs-toggle="list" href="#list-vendredi" role="tab" aria-controls="list-vendredi">Vendredi</a>
                <a className="list-group-item list-group-item-action" id="list-samedi-list" data-bs-toggle="list" href="#list-samedi" role="tab" aria-controls="list-samedi">Samedi</a>
              </div>
            </div>
            <div className="col-lg-8 col-sm-12">
              <div className="tab-content" id="nav-tabContent">
                {
                  ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'].map((items, pos)=>{
                    return <div key={`pro-${pos}`} className={pos === 0 ? 'tab-pane fade show active': 'tab-pane fade'} id={`list-${items}`} role="tabpanel" aria-labelledby={`list-${items}-list`}>
                      <div className="widget-header text-center">
                      <h3 className="widget-title">{(items || '').toUpperCase()}</h3>
                      <img src={Wave} className="wave" alt="wave" />
                    </div>
                    {
                      (dataList[pos] || []).map((item, position)=>{
                        return <DateEvent content={item} key={`pro-${pos}-${position}`}/>
                      })
                    }
                  </div>
                  })
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-4">
        <SideBar />
      </div>
    {/* new Date().getDay() */}
    </div>
  </div>
</section>);
}


export default Programmation;
