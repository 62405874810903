import React, { useEffect, useState } from 'react';

const PubSection = ({content})=>{
  return (<div className="post post-carousel">
	<div className="thumb rounded">
		<a href="#/">
			<div className="inner">
				<img src={content.images} style={{height: "320px"}} alt="post-title" />
			</div>
		</a>
	</div>
	<h5 className="post-title mb-0 mt-4"><a href="#/">&emsp;</a></h5>
	<ul className="meta list-inline mt-2 mb-0">
	</ul>
	<ul className="social-icons list-unstyled list-inline mb-0">
		<li className="list-inline-item"><a href="#/"><i className="fab fa-facebook-f"></i></a></li>
		<li className="list-inline-item"><a href="#/"><i className="fab fa-instagram"></i></a></li>
	</ul>
</div>);
}


export default PubSection;
