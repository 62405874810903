import React, { useEffect, useState } from 'react';
import Preloader from './../components/Preloader';
import HeroSection from './../components/HeroSection';
import Title from '../components/Title';
import VideoFrame from '../components/VideoFrame';
import DateEvent from '../components/DateEvent';
import ContentAnimateur from '../components/ContentAnimateur';
import SideBar from '../components/SideBar';
import Wave from './../images/wave.svg'
import dataList from './../data/ephemerid';
const monthList = ['Janvier', 'Fevrier', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
const currentDate = new Date();

const Home = ()=>{
  const [isLoad, setIsload] = useState(true);
  const dateFormat = `${currentDate.getDate()} ${monthList[currentDate.getMonth()]}` ;
  const data = dataList.find((item)=> item.date ===  `${currentDate.getDate()}-${currentDate.getMonth()+1}`) || {date: '', data: []};
  const [currentData, setCurrentData] = useState(data);

  if(!isLoad){ return <Preloader /> }

  return (<>
    <HeroSection />
    <section className="main-content">
      <div className="container-xl">
        <div className="row gy-4">
          <div className="col-lg-8">

            <div className="spacer" data-height="5"></div>
            <div className="spacer" data-height="50"></div>
            <Title txt_title={"Vidéos"}/>

            <div className="padding-30 rounded bordered">
              <div className="row">
                {
                  [{
                    embed_link: 'https://www.youtube.com/embed/K44cU9nYJjg',
                    title: ''
                  },{
                    embed_link: 'https://www.youtube.com/embed/Lv_Y8CUhMGY',
                    title: ''
                  }].map((item, pos)=>{
                    return <VideoFrame content_media={item} key={`hmv-${pos}`}/>
                  })
                }
              </div>
            </div>

            <div className="spacer" data-height="50"></div>
            <Title txt_title={"Émissions Récentes"}/>
            <div className="padding-30 rounded bordered">
              <div className="row">
                <div className="col-md-12 col-sm-12">
                  <div className="details">
                    <p className="excerpt mb-0">
                      <iframe width="100%" height="315" src="https://www.youtube.com/embed/videoseries?list=PLZWMx1o--hGyTZXCyxJc3ZHFWKqwOcbE3&rel=0" title="Ti Limyè sou Listwa" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

                      <iframe title={"widget souncloud"} width="100%" height="300" scrolling="no" frameBorder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1485994375&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe><div style={{
                        fontSize: "10px", color: "#cccccc", lineBreak: "anywhere", wordBreak: "normal", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", fontFamily: "Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif", fontWeight: 100
                      }}><a href="https://soundcloud.com/radio-rezistans-fm" title="Radio Rezistans FM" target="_blank" style={{color: "#cccccc", textDecoration: "none"}} rel="noreferrer">Radio Rezistans FM</a> · <a href="https://soundcloud.com/radio-rezistans-fm/sets/emission" title="Émission" target="_blank" style={{color: "#cccccc", textDecoration: "none"}} rel="noreferrer">Émission</a></div>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="spacer" data-height="50"></div>
            <Title txt_title={"Dat ak evenman espesyal"}/>
            <div className="widget-header text-center">
              <h3 className="widget-title">{dateFormat}</h3>
              <img src={Wave} className="wave" alt="wave" />
            </div>
            {
              (currentData.data || []).map((item, pos)=>{
                return (<DateEvent content={item} key={`hmd-${pos}`} />)
              })
            }
            
            <div className="spacer" data-height="50"></div>
            <Title txt_title={"Nos Animateurs"}/>
            <div className="row post-carousel-twoCol post-carousel">
              {
                [
                  {fullname: 'Dr. Roseman Aspilaire', media_profil: require('./../images/rosemon-aspi.jpeg')},
                  {fullname: 'Mildrède Béliard', media_profil: require('./../images/midl-belliard.jpeg')},
                ].map((item, pos)=>{
                  return <ContentAnimateur content={item} key={`hmca-${pos}`}/>
                })
              }
            </div>
            <div className="spacer" data-height="50"></div>
          </div>

          <div className="col-lg-4">
             <SideBar />
          </div>
      </div>
    </div>
  </section>
</>);
}


export default Home;
