import React, { useEffect, useState } from 'react';
import PubSection from './PubSection';

const HeroSection = ()=>{
  return (<section id="hero">

  <div className="container-xl">

    <div className="row gy-4">
      
      <div className="col-lg-8">
        <div className="post featured-post-lg">
          <div className="details clearfix">
          </div>
          <a href="#blog-single.html">
            <div className="thumb rounded">
              <div className="inner data-bg-image" data-bg-image={require('./../images/sliderp1.png')}></div>
            </div>
          </a>
        </div>

      </div>

      <div className="col-lg-4">
        <div className="widget rounded">
          <div className="widget-content">
            <div className="post-carousel-widget">
              {
                [
                  {title: '', images: require('./../images/Logo-High-Life-Kreyasyon-No-BG.png')},
                  {title: '', images: require('./../images/Quint.jpeg')},
                  {title: '', images: require('./../images/Logo-Radio-Choublak.png')},
                ].map((item, pos)=>{
                  return <PubSection content={item} key={`hs-${pos}`} />
                })
              }
            </div>
            <div className="slick-arrows-bot">
              <button type="button" data-role="none" className="carousel-botNav-prev slick-custom-buttons" aria-label="Previous"><i className="fas fa-arrow-alt-circle-left"></i></button>
              <button type="button" data-role="none" className="carousel-botNav-next slick-custom-buttons" aria-label="Next"><i className="fas fa-arrow-alt-circle-right"></i></button>
            </div>
          </div>		
        </div>
        
      </div>

    </div>

  </div>

</section>);
}


export default HeroSection;
