import React, { useEffect, useState } from 'react';
import Preloader from './../components/Preloader';
import HeroSection from './../components/HeroSection';
import Title from '../components/Title';
import VideoFrame from '../components/VideoFrame';
import DateEvent from '../components/DateEvent';
import ContentAnimateur from '../components/ContentAnimateur';
import SideBar from '../components/SideBar';
import Wave from './../images/wave.svg';
import Breadcrumb from '../components/Breadcrumb';

const About = ()=>{
  const [isLoad, setIsload] = useState(true);
  if(!isLoad){ return <Preloader /> }

  return (<section className="main-content">
  <div className="container-xl">
  <Breadcrumb title={"A propos"} />
    <div className="row gy-4">
    <div className="col-lg-8">

      <div className="page-content bordered rounded padding-30">
          <img src={'https://www.rezistansfm.com/wp-content/uploads/2022/05/sliderp1.png'} alt="" className="rounded mb-4" />
          <div className="inner data-bg-image" data-bg-image="https://www.rezistansfm.com/wp-content/uploads/2022/05/sliderp1.png"></div>
          <p>
          <strong>&emsp;&emsp;&emsp;Rezistans FM</strong> est une station de radio privée émettant sur la bande FM depuis Miragoâne via le <em>104.3 MHz.</em> Elle est un projet à caractère social s’inscrivant dans le combat perpétuel contre la déshumanisation de l’Homme noir. 
          </p>
          <p>
          La programmation de Rezistans FM est articulée autour des enjeux socio-économiques et politiques locaux, nationaux et internationaux. Elle accorde également une grande place à l’histoire nègre et aux faits connus, méconnus et mal connus de l’histoire d’Haïti. 
          </p>
          <p>
          Bien qu’émettant depuis le Département des Nippes, Rezistans FM œuvre pour un plus large public qui peut suivre ses différentes émissions à partir des plateformes web et des réseaux sociaux. 
          </p>
          {/* <p><strong>&emsp;Ti Limyè sou Listwa<br /></strong>
            &emsp;&emsp;Eske se Boukman ki te chef soulèvman 22 ak 23 dawout 1791 la?
            Yon lane anvan òganizasyon kongrè Bwa Kayiman an, sa vle di an 1790, Boukman pat chef ankenn gwoup ni gen okenn tit ak grad militè anndan koloni-an. Dayè, plizyè mwa avan reyinyon Mòn Wouj yo, chèf revòlte yo te remèt komandman soulèvman 25 dawout bay Jean-Francois, alyas Petekou.
            Men Boukman se te yon antchoupwèt, yon dan devan ki pat toujou jere bon moman pou li poze aksyon yo. Se nan konsa li te atake bitasyon kolon yo 17 dawout 1791 aloske dat ki te planifye pou lanse soulèvman-an se te 25 dawout. Sa ki te lakoz chèf revòlte yo te oblije avanse dat atak la pou lannwit 22 louvri 23 dawout 1791.
            Pou pini dezobeyisans Boukman nan, Jean-Francois Petekou te bay George, youn nan revolte yo, lòd pou li touye li menm ak yon lòt kanmarad li ki rele Paul Blin kote l kwaze ak yo. Listwa rapòte ke se blan kolon yo ki te jwenn lòd sa sou yon moso papye ki te nan pòch George.
            Sous :
            <ul>
              <li>-&emsp;Hérard Dumesle,1824 : Voyage dans le Nord d’Hayti ou Révélations des lieux et des monuments historiques</li>
              <li>-&emsp;France Convention Nationale : Débats entre les accusateurs et les accusés dans l’affaire, imprimés en exécution de la loi du 4 pluviose. Tome 1. Paris, 1795</li>
              <li>-&emsp;Gros Verneuil, Paris 1793 Isle de St.-Domingue, province du nord… : Précis historique</li>
              <li>-&emsp;Yves Benot : “Les insurgés de 1791, leurs dirigeants et l’idée d’indépendance”. In : les lumières, les esclavages, la colonisation. Édition La Découverte, 2005</li>
            </ul>
          </p> */}
          <hr className="my-4" />
          <ul className="social-icons list-unstyled list-inline mb-0">
              <li className="list-inline-item"><a href="#"><i className="fab fa-facebook-f"></i></a></li>
              <li className="list-inline-item"><a href="#"><i className="fab fa-twitter"></i></a></li>
              <li className="list-inline-item"><a href="#"><i className="fab fa-instagram"></i></a></li>
              <li className="list-inline-item"><a target="_blank" href="https://soundcloud.com/radio-rezistans-fm" rel="noreferrer"><i className="fab fa-soundcloud"></i></a></li>
		          <li className="list-inline-item"><a target="_blank" href="https://www.youtube.com/channel/UCjYMLDsJsW0bg6cClVOyEqw/featured" rel="noreferrer"><i className="fab fa-youtube"></i></a></li>
          </ul>
      </div>
    </div>

    <div className="col-lg-4">
      <SideBar />
    </div>
  </div>
</div>
</section>);
}


export default About;
