import logo from './logo.svg';

import {
  BrowserRouter,
  Switch,
  Routes,
  Route,
  Link
} from "react-router-dom";
import Home from './pages/Home';
import Contact from './pages/Contact';
import Programmation from './pages/Programmation';
import NotFound from './pages/NotFound';
import About from './pages/About';
import Animateur from './pages/Animateur';
import Header from './components/Header';
import InstagramFeed from './components/InstagramFeed';
import Footer from './components/Footer';
import PlayerBar from './components/PlayerBar';
import CanvasMenu from './components/CanvasMenu';

function App() {
  return (<BrowserRouter>
      <div className="site-wrapper">
        <div className="main-overlay"></div>
        <Header />
        <Routes>
            <Route index element={<Home />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/programmation" element={<Programmation />} />
            <Route path="/animateur" element={<Animateur />} />
            <Route path="/laradio" element={<About />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
        <InstagramFeed />
        <Footer />
      </div>
      <div className="spacer" data-height="120"></div>
      <CanvasMenu />
      <PlayerBar />
  </BrowserRouter>);
}

export default App;
