import React, { useEffect, useState } from 'react';

const Footer = ()=>{
  return (<footer>
		<div className="container-xl">
			<div className="footer-inner">
				<div className="row d-flex align-items-center gy-4">
					<div className="col-md-4">
						<span className="copyright">© 2022 Radio RezistansFM.</span>
					</div>
					<div className="col-md-4 text-center">
						<ul className="social-icons list-unstyled list-inline mb-0">
							<li className="list-inline-item"><a href="#/"><i className="fab fa-facebook-f"></i></a></li>
							<li className="list-inline-item"><a href="#/"><i className="fab fa-twitter"></i></a></li>
							<li className="list-inline-item"><a href="#/"><i className="fab fa-instagram"></i></a></li>
							<li className="list-inline-item"><a target="_blank" href="https://soundcloud.com/radio-rezistans-fm" rel="noreferrer"><i className="fab fa-soundcloud"></i></a></li>
							<li className="list-inline-item"><a target="_blank" href="https://www.youtube.com/channel/UCjYMLDsJsW0bg6cClVOyEqw/featured" rel="noreferrer"><i className="fab fa-youtube"></i></a></li>
						</ul>
					</div>
					<div className="col-md-4">
						<a href="#" id="return-to-top" className="float-md-end"><i className="fas fa-arrow-alt-circle-up"></i>retour</a>
					</div>
				</div>
			</div>
		</div>
	</footer>);
}


export default Footer;
