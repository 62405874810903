import React, { useEffect, useState } from 'react';

const VideoFrame = ({content_media})=>{
  return (<div className="col-md-6 col-sm-6">
  <div className="details">
    <p className="excerpt mb-0">
      <iframe src={content_media.embed_link} title={content_media.title} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    </p>
  </div>
</div>);
}


export default VideoFrame;
