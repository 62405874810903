import React, { useEffect, useState } from 'react';
import Wave from './../images/wave.svg'
const SideBar = ({title})=>{
  return (<div className="sidebar">
  <div className="widget rounded">
    <div className="widget-about data-bg-image text-center" data-bg-image="images/map-bg.png">
      <img src={require('./../images/logo-rezistans.jpeg')} style={{width: "100%", height: "180px"}} alt="logo" className="mb-4" />
      <p className="mb-4">
        Zansèt nou yo te mawon nan mòn pou reziste kont sistèm esklavaj la. Se te mwayen pou yo rasanble, twoke lide e monte estrateji lit ki ta pral mennen yo sou wout libète. Aprè Zansèt yo te fin kase chenn esklavaj yo, yon lòt sistèm tòde sèvo nou epi mete ansyen chenn sa yo nan lespri nou.
Alòs, nou dwe bay Rezistans la yon lòt fòm. Pou sa nap kòmanse pa aksepte orijin nou, nap swen lespri nou ak bon konesans sou lasyans, lakilti ak listwa peyi nou. Alèkile, se nouvo fòm Rezistans sa nou dwe chwazi pou libere lespri nou e se pou sa Radyo Rezistans FM egziste !
      </p>
      <ul className="social-icons list-unstyled list-inline mb-0">
        <li className="list-inline-item"><a target="_blank" href="#"><i className="fab fa-facebook-f"></i></a></li>
        <li className="list-inline-item"><a target="_blank" href="#"><i className="fab fa-twitter"></i></a></li>
        <li className="list-inline-item"><a target="_blank" href="#"><i className="fab fa-instagram"></i></a></li>
        <li className="list-inline-item"><a target="_blank" href="https://soundcloud.com/radio-rezistans-fm" rel="noreferrer"><i className="fab fa-soundcloud"></i></a></li>
        <li className="list-inline-item"><a target="_blank" href="https://www.youtube.com/channel/UCjYMLDsJsW0bg6cClVOyEqw/featured" rel="noreferrer" ><i className="fab fa-youtube"></i></a></li>
      </ul>
    </div>
  </div>
  <div className="widget rounded">
    <div className="widget-header text-center">
      <h3 className="widget-title">Bulletin d'information</h3>
      <img src={Wave} className="wave" alt="wave" />
    </div>
    <div className="widget-content">
      <span className="newsletter-headline text-center mb-3">Rejoignez nos abonnés!</span>
      <form>
        <div className="mb-2">
          <input className="form-control w-100 text-center" placeholder="Email address…" type="email" />
        </div>
        <button className="btn btn-default btn-full" type="submit">Enregistrer</button>
      </form>
      <span className="newsletter-privacy text-center mt-3">En vous inscrivant, vous acceptez notre <a href="#">Politique de confidentialité</a></span>
    </div>		
  </div>
</div>);
}


export default SideBar;
