import React, { useEffect, useState } from 'react';
import Logo from './../images/logo.svg'
import {
  useLocation, Link
} from "react-router-dom";

const Preloader = ()=>{
  const location = useLocation();
  
  return (<header className="header-default">
  <nav className="navbar navbar-expand-lg">
    <div className="container-xl">
      <Link className="navbar-brand" to="/"><img src={Logo} style={{width: '70px', height: '70px'}} alt="logo" /></Link> 
      <div className="collapse navbar-collapse">
        <ul className="navbar-nav mr-auto">
          <li className={`nav-item ${location.pathname === '/' ? 'active': ''}`}>
            <Link className="nav-link" to="/">Accueil</Link>
          </li>
          <li className={`nav-item ${location.pathname.includes('laradio') ? 'active': ''}`}>
            <Link className="nav-link" to="/laradio">La radio</Link>
          </li>
          <li className={`nav-item ${location.pathname.includes('programmation') ? 'active': ''}`}>
            <Link className="nav-link" to="/programmation">Programmation</Link>
          </li>
          <li className={`nav-item ${location.pathname.includes('animateur') ? 'active': ''}`}>
            <Link className="nav-link" to="/animateur">Nos Animateurs</Link>
          </li>
          <li className={`nav-item ${location.pathname.includes('contact') ? 'active': ''}`}>
            <Link className="nav-link" to="/contact">Nous contacter</Link>
          </li>
        </ul>
      </div>
      <div className="header-right">
        <ul className="social-icons list-unstyled list-inline mb-0">
          <li className="list-inline-item"><a href={"#/"}><i className="fab fa-facebook-f"></i></a></li>
          <li className="list-inline-item"><a href={"#/"}><i className="fab fa-twitter"></i></a></li>
          <li className="list-inline-item"><a href={"#/"}><i className="fab fa-instagram"></i></a></li>
          <li className="list-inline-item"><a target="_blank" href="https://soundcloud.com/radio-rezistans-fm" rel="noreferrer"><i className="fab fa-soundcloud"></i></a></li>
          <li className="list-inline-item"><a target="_blank" href="https://www.youtube.com/channel/UCjYMLDsJsW0bg6cClVOyEqw/featured" rel="noreferrer" ><i className="fab fa-youtube"></i></a></li>
        </ul>
        <div className="header-buttons">
          <button className="burger-menu icon-button">
            <span className="burger-icon"></span>
          </button>
        </div>
      </div>
    </div>
  </nav>
</header>);
}


export default Preloader;
