import React, { useEffect, useState } from 'react';
import Wave from './../images/wave.svg'

const Title = ({txt_title})=>{
  return (<div className="section-header">
	<h3 className="section-title">{txt_title}</h3>
	<img src={Wave} className="wave" alt="wave" />
</div>);
}


export default Title;
