import React, { useEffect, useState } from 'react';

const IntagramFeed = ()=>{
  return (<div className="instagram">
  <div className="container-xl">
    <a href="#/" className="btn btn-default btn-instagram">@RezistansFM sur Instagram</a>
    <div className="instagram-feed d-flex flex-wrap">
      <div className="insta-item col-sm-2 col-6 col-md-2">
        <a href="#/">
          <img style={{minHeight: "180px", maxHeight: "180px"}} src="https://via.placeholder.com/300x200.png" alt="insta-title" />
        </a>
      </div>
      <div className="insta-item col-sm-2 col-6 col-md-2">
        <a href="#/">
          <img style={{minHeight: "180px", maxHeight: "180px"}} src="https://via.placeholder.com/300x200.png" alt="insta-title" />
        </a>
      </div>
      <div className="insta-item col-sm-2 col-6 col-md-2">
        <a href="#/">
          <img style={{minHeight: "180px", maxHeight: "180px"}} src="https://via.placeholder.com/300x200.png" alt="insta-title" />
        </a>
      </div>
      <div className="insta-item col-sm-2 col-6 col-md-2">
        <a href="#/">
          <img style={{minHeight: "180px", maxHeight: "180px"}} src="https://via.placeholder.com/300x200.png" alt="insta-title" />
        </a>
      </div>
      <div className="insta-item col-sm-2 col-6 col-md-2">
        <a href="#/">
          <img style={{minHeight: "180px", maxHeight: "180px"}} src="https://via.placeholder.com/300x200.png" alt="insta-title" />
        </a>
      </div>
      <div className="insta-item col-sm-2 col-6 col-md-2">
        <a href="#/">
          <img style={{minHeight: "180px", maxHeight: "180px"}} src="https://via.placeholder.com/300x200.png" alt="insta-title" />
        </a>
      </div>
    </div>
  </div>
</div>);
}


export default IntagramFeed;
