import React, { useEffect, useState } from 'react';
import {
  useLocation, Link
} from "react-router-dom";

const CanvasMenu = ()=>{
	const location = useLocation();
	
  return (<div className="canvas-menu d-flex align-items-end flex-column">
	<button type="button" className="btn-close" aria-label="Close"></button>
	<div className="logo">
		<img src={require('./../images/logo-rezistans.jpeg')} alt="RTH" />
	</div>
	<nav>
		<ul className="vertical-menu">
			<li className={`${location.pathname === '/' ? 'active': ''}`}>
				<Link to={'/'}>Accueil</Link>
			</li>
			<li className={`${location.pathname.includes('laradio') ? 'active': ''}`}>
				<Link to={'/laradio'}>La Radio</Link>
			</li>
			<li className={`${location.pathname.includes('programmation') ? 'active': ''}`}>
				<Link to={'/programmation'}>Programmation</Link>
			</li>
			<li className={`${location.pathname.includes('animateur') ? 'active': ''}`}>
				<Link to={'/animateur'}>Nos Animateurs</Link>
			</li>
			<li className={`${location.pathname.includes('contact') ? 'active': ''}`}>
				<Link to={'/contact'}>Nous contacter</Link>
			</li>
		</ul>
	</nav>
	<ul className="social-icons list-unstyled list-inline mb-0 mt-auto w-100">
		<li className="list-inline-item"><a href="#"><i className="fab fa-facebook-f"></i></a></li>
		<li className="list-inline-item"><a href="#"><i className="fab fa-twitter"></i></a></li>
		<li className="list-inline-item"><a href="#"><i className="fab fa-instagram"></i></a></li>
		<li className="list-inline-item"><a target="_blank" href="https://soundcloud.com/radio-rezistans-fm" rel="noreferrer"><i className="fab fa-soundcloud"></i></a></li>
		<li className="list-inline-item"><a target="_blank" href="https://www.youtube.com/channel/UCjYMLDsJsW0bg6cClVOyEqw/featured" rel="noreferrer"><i className="fab fa-youtube"></i></a></li>
	</ul>
</div>);
}


export default CanvasMenu;
