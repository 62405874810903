import React, { useEffect, useState } from 'react';

const DateEvent = ({content})=>{
  return (<><div className="col-md-12 col-sm-6">
  <div className="details rounded bordered p-2">
    <h5 className="post-title"><a href="#blog-single.html">{content.title}</a></h5>
    <p className="excerpt mb-0">
      {content.description}
    </p>
  </div>
</div>
<div className="spacer" data-height="10"></div></>);
}


export default DateEvent;
