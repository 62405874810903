module.exports = [
  {
    date: '22-8',
    data: [
      {
        title: `1797. Le commissaire Sonthonax est expulsé de Saint Domingue par
        Toussaint Louverture`,
        description: `À l’origine de la proclamation libérant les esclaves dans la province du Nord de Saint-Domingue, et
        de la nomination de Toussaint Louverture au grade de général en chef, Sonthonax s’est vu forcer
        de quitter la colonie. Dans le récit qu’il a envoyé au Directoire, Toussaint l’accuse de vouloir
        déclarer l’indépendance et de prôner le massacre de la population blanche.`
      },
      {
        title: `1798. Les anglais évacuèrent Jérémie:`,
        description: `Invités en 1793 par les colons français qui préférèrent se rallier sous le drapeau britannique plutôt
        que d’accepter les mulâtres comme des des citoyens à part entière, les Anglais s’étaient installés
        confortablement dans la ville jusqu’au moment où André Rigaud et ses troupes bloquèrent la ville
        au nom de la France. Refusant de traîter avec un ennemi juré de la couronne , ils abandonnèrent la
        ville et se rendirent au Môle Saint Nicolas.`
      },
      {
        title: `1889. Le président François Denis Légitime part pour l’exil:`,
        description: `Protagoniste de la guerre civile opposant le gouvernement siégeant à la Capitale au gouvernement
        septentrional siégeant au Cap, Légitime qui avait été abandonné par nombre de ses généraux prit
        le chemin de l’exil à bord du croiseur français leKerguelen. Son gouvernement n’a duré que huit
        mois (16 Décembre 1888).`
      }
    ]
  },
  {
    date: '23-8',
    data: [
      {
        title: `1791. Révolte des esclaves de Saint-Domingue:`,
        description: `Dans la nuit du 22 au 23 août 1791, les esclaves de la colonie française de Saint-Domingue, se
        soulèvent contre leurs maîtres. La révolte est menée par Boukman, un prêtre vaudou, qui une
        semaine auparavant avait préparé les esprits et conclu un pacte de sang durant une cérémonie
        vodou (nuit du 14 août 1791). Cette révolte marque le début d’une longue période de prise de
        conscience, d’émancipation et qui devait aboutir à l’indépendance d’Haïti le 1er janvier 1804. En
        1998, L’UNESCO fit du 23 août « la Journée internationale du souvenir de la traite négrière et de
        son abolition » pour marquer le rôle déterminant de cette révolte dans l’abolition de la traite
        négrière transatlantique.`
      },
      {
        title: `1963. Le parlement convoqué en session extraordinaire accorde les pleins
        pouvoirs au président François Duvalier:`,
        description: `Quelques mois plus tard, Duvalier s’autoproclamera président à vie avec le droit de désigner son
        successeur`
      }
    ]
  },
  {
    date: '24-8',
    data: [
      {
        title: `1802. Toussaint Louverture, prisonnier de Napoléon, arrive au Fort de Joux:`,
        description: `Arrêté le 7 juin de la même année et embarqué pour la France pour la France le 15 juin suivant,
        Toussaint Louverture destitué de son grade de général de division de Saint Domingue arrive dans
        ce château datant du Moyen-Âge et transformé en prison d’état au milieu du 18e siècle.
        Il n’y sortit jamais ayant rendu dans un cachot froid et humide de ce fort le 7 avril 1803`
      }
    ]
  },
  {
    date: '25-8',
    data: [
      {
        title: `1818. La Citadelle Henri (Laferrière) foudroyée:`,
        description: `Vers 6 heures du soir lors d’un orage, la foudre tomba sur la citadelle et fit sauter la salle d’artifice.
        Henri Christophe et son état-major se porta en toute hate à la Ferrière, étouffa l’incendie qui se
        propageait malgré la pluie et sauva le fort d’une explosion certaine. Y périrent le prince Noël,
        beau-frère du roi et duc de Port-de-Paix, plusieurs officiers et soldats. Des travaux de réparations
        commencèrent quelques jours plus tard at s’achevèrent des les premiers d’octobre.`
      },
      {
        title: `1849. La Chambre des députés conféra au président Faustin Soulouque le titre
        d’Empereur d’Haiti:`,
        description: `Ayant réussi à vaincre la doublure à laquelle voulait le soumettre la bourgeoisie politique et
        soutenu par une armée fidèle, Faustin Soulouque, président du 1er mars 1847, présenta au
        parlement une pétition signée par des sympathisants pour faire d’Haiti un empire. Les
        représentants des communes votèrent affirmativement. Le lendemain, les sénateurs donnèrent
        leur soutien au projet.`
      }
    ]
  },
  {
    date: '26-8',
    data: [
      {
        title: `1849. Le Sénat de la République d’Haiti conféra au président Faustin
        Soulouque le titre d’Empereur d’Haiti:`,
        description: `Soulouque qui, en décembre 1848 avait revisé la Constitution de 1846 pour mieux contrôler les
        différentes branches du gouvernement, se laissa, un an après, convaincre par ses conseillers de se
        faire consacrer empereur d’Haiti. Le corps législatif par un acte de la Chambre des députés la
        veille, et du Sénat en ce jour, ne fait qu’entériner ce projet en lui conférant le titre. Une
        constitution impériale fut publiée quatre semaines plus tard (20 septembre 1849).`
      }
    ]
  },
  {
    date: '27-8',
    data: [
      {
        title: `1793. Proclamation de l’abolition de l’esclavage dans la partie de l’Ouest de
        Saint-Domingue par Polverel:`,
        description: `Théâtre de guerres entre la France et l’Angleterre d’une part et la France et l’Espagne d’autre part,
        Saint Domingue se révéla, pour les commissaires Polvelre, Ailhaud et Sonthonax qui devaient
        également affronter la furie des grand possédants (voir: 17 septembre 1792), une terre
        difficilement contrôlable. Cherchant à entrer dans les bonnes grâces des noirs insurgés, ils
        commencèrent donc à adopter des mesures ponctuelles de confirmation de leur liberté puis par la
        proclamation en deux temps de l’abolition: d’abord dans l’Ouest et ensuite dans le Nord.`
      },{
        title: `1986. Décès de Daniel Fignolé à Port-au-Prince:`,
        description: `« Nul n’a su mieux que lui incarner et exprimer, tout au long de la période, les aspirations du
        peuple de notre pays à la justice et à la dignité. » (Marc Bazin au lendemain de son décès, cité
        par Carlo Désinor, p. 223.)`
      },{
        title: `1984. Décret fixant le salaire minimum à payer à partir du 1er octobre
        1984:`,
        description: `Concerne les travailleurs occupés dans les entreprises industrielles, commerciales et agricoles de
        « l’aire de la communauté urbaine ». En application du décret du 24 fév. 1984 actualisant le Code
        du travail du 12 sept. 1961 (SL 1961 – Haïti 1). Ce decret sera amendé le 4 mai 1995.`
      }
    ]
  },
  {
    date: '28-8',
    data: [
      {
        title: `1994. Assassinat du prêtre Jean-Marie Vincent:`,
        description: `Prêtre Montfortains, il fut criblé de balles alors qu’il regagnait la résidence de sa congrégation. Les
        assassins n’ont jamais été appréhendés.`
      }
    ]
  },{
    date: '29-8',
    data: [
      {
        title: `1793. Proclamation de l’abolition de l’esclavage dans la partie Nord de SaintDomingue par Sonthonax:`,
        description: `Proclamation lue solennelle sur la Place du Champs de Mars et qui décréta que « Tous les Nègres,
        sang-mêlés actuellement dans l’esclavage sont déclarés libres pour jouir de tous les droits attachés
        à la qualité de citoyens français ». Un leurre, puisque Sonthonax, après cette proclamtion instaura
        un régime de liberté conditionnelle ou surveillée et de travaux forcés. La Convention n’abolira
        l’esclavage que quelques cinq mois après, le 4 Février 1794. (voir: 27 septembre 1792)
        Le même jour, Toussaint, alors officier de l’armée espagnole, publia du camp Turel près des
        Gonaïves, une proclamation dans laquelle ils se présenta comme celui capable de garantir la liberté
        et l’égalité à Saint Domingue, invitant ainsi les esclaves à le suivre.`
      },{
        title: `1840. Adhésion d’Haiti aux traités sur la repression de la traîte des noirs:`,
        description: `Sollicitée par les gouvernement britannique et français, Haiti adhéra à ces traités signées
        notamment le 31 novembre 1831 et le 22 mars 1833 par les gouvernements sus-mentionnés,
        montrant par là, qu’elle était très intéressée à la question anti-escalvagiste. Quelques années plus
        tard, sous le gouvernement de Fabre Geffrard, elle ira encore beaucoup plus loin encourageant
        l’immigration des noirs des Etats-Unis en Haiti`
      }
    ]
  },{
    date: '30-8',
    data: [
      {
        title: `1915. Charlemagne Péralte refusa d’obéir les Américains:`,
        description: `Charlemagne Péralte rencontra « pour la première fois les Marines … à Léogâne, ou, en sa qualité
        de commandant de l’arrondissement militaire, il [refusa] obstinément d’amener le drapeau haitien
        et de rendre les armes sans un ordre formel venant du président Dartiguenave lui-même. »`
      },{
        title: `1959. Un groupe de mercenaires cubains se retrouvèrent sur le sol d’Haiti
        avec l’intention de provoquer la chute du gouvernement:`,
        description: `Cette tentative d’invasion fut écrasée après seulement cinq jours. Un des envahisseurs avait,
        paraît-il, des liens de parenté avec l’ancien candidat à la présidence, Louis Déjoie.`
      }
    ]
  },{
    date: '31-8',
    data: [
      {
        title: `1798. Le général anglais Maitlard remet le Môle Saint Nicolas à Toussaint
        Louverture:`,
        description: `« Ce même jour-là, un traité de paix fut signé entre Toussaint et le représentant de l’Angleterre. Ce
        traité fut ratifié le 9 Janvier 1799. »`
      }
    ]
  },
  {
    date: '1-9',
    data: [
      {
        title: `1842. Fondation aux Cayes de la « Société des Droits de l’Homme et du Citoyen »:`,
        description: `Ayant pour président Hérard Dumesle, cette société, malgré son nom se présentait plutôt comme un parti d’opposition au gouvernement du président Jean Pierre Boyer. Ses membres, par cet outil politique, préparaient l’après-Boyer. En effet, leur acte de naissance porte la résolution suivante:
        « II sera proclamé un gouvernement provisoire, composé des notabilités tant dans la magistrature que dans l’armée et qui sont les citoyens Imbert, Bonnet, Borgella, Voltaire et Guerrier. Les haïtiens comptent sur le patriotisme de ces grands citoyens, qui ont si souvent donné des preuves de leur dévouement quand il s’est agi du bien public pour accepter la haute mission qui leur est déférée par tous les cœurs. Le gouvernement provisoire constitué, l’autorité du pouvoir exécutif ainsi que celle du sénat et de la chambre des représentants des communes, cesseront. »`
      }
    ]
  },
  {
    date: '2-9',
    data: [
      {
        title: `1792. Martyre du Bienheureux Jacques Jules Bonnaud et de ses compagnons:`,
        description: `Ils furent immolés lors des massacres de septembre 1792 à Paris. C’est le premier bienheureux né sur le sol d’Haiti, au Cap-Français en 1741.`
      },
      {
        title: `1804. Dessalines proclamé empereur par l’état-major et la quatrième demi-brigade:`,
        description: `D’après Madiou les raisons d’une telle proclamation réside dans le fait que « le titre de gouverneur général décerné au citoyen Jean-Jacques Dessalines ne remplit pas le voeu général, puisqu’il suppose un pouvoir secondaire dépendant d’une autorité étrangère…. (Histoire d’Haiti livre 37, p. 153)`
      }
    ]
  },
  {
    date: '3-9',
    data: [
      {
        title: `1793. Les colons Français de la Grande Anse signèrent avec l’Angleterre un traité d’intervention:`,
        description: `Les colons qui ne faisaient plus confiance aux autorités de la métropole et qui s’opposaient farouchement aux droits accordés aux mulâtres demandèrent la protection de l’Angleterre alors en guerre avec la France.`
      },
      {
        title: `1859. Tentative d’assassinat contre le président Fabre Geffrard`,
        description: `Au soir de ce jour, des instigateurs s’apostèrent aux environs de la maison de Mme Blanfort, fille du président Geffrard, pour attenter aux jours du chef de l’Etat, quand il viendrait, à son ordinaire, chez sa fille. L’un d’eux eut l’idée de tuer sa fille pour attirer le père qui tardait : à travers la persienne, il déchargea sontrabouc et boya la tête de Mme Blanfort. Les conjurés , effrayés de ce coup, prirent aussitôt la fuite. La répression ne tarda pas et fut très sévère.`
      },{
        title: `1918. Evasion de Charlemagne Péralte de la prison du Cap-Haïtien:`,
        description: `« Pendant que se répandit le bruit de sa fuite, Charlemagne se réfugia à la Rue 4 chez son camarade Gabriel Sémonville… qui lui procura des vêtements de femme avec lesquels il se déguisa pour [laisser la ville].`
      },{
        title: `1959. Décès de Sténio Vincent:`,
        description: `Président d’Haïti de 1930 à 1941,  Sténio Vincent, un membre de l’élite minoritaire d’Haiti, fut avant son accession à la président un journaliste, un écrivain, un diplomate, et maire de Port-au-Prince. Élu pour six ans, il arriva à se maintenir au pouvoir durant près de 11 ans. La fin de l’occupation américaine et le massacre des milliers d’Haïtiens en République Dominicaine eurent lieu sous son gouvernement.`
      }
    ]
  },
  {
    date: '4-9',
    data: [
      {
        title: `1881. Ouverture officielle de notre premiere exposition agricole et industrielle nationale:`,
        description: `Cette exposition qui dura environ trois mois, et qui coûta 64.053 piastres et 39 centimes, traîna une foule nombreuse venue admirer les quelques 2131 objects exposés, participer à la grande loterie nationale, qui fit 341 gagnants, et aux courses équestres.`
      }
    ]
  },
  {
    date: '5-9',
    data: [
      {
        title: `1998. Décès de Félix Morisseau-Leroy:`,
        description: `Poète et dramaturge, Félix Morisseau-Leroy naquit à Grand Gosier (département du Sud-Est) le 13 mars 1912. Promoteur de la langue qu’il enrichit à travers ses oeuvres, il expira à Miami Florida où il avait élu domicile à son retour de Sénégal.`
      }
    ]
  },
  {
    date: '6-9',
    data: [
      {
        title: `1902. Destruction du navire Haitien « La Crête à Pierrot » avec à son bord l’Amiral Killick:`,
        description: `L’Amiral Killick qui s’était révolté contre le gouvernement provisoire de 1902, stoppa un vapeur allemand qui transportait un chargement d’armes et munitions envoyé par gouvernement aux rebelles du Nord. Il fut dénoncé par Port-au-Prince et assimilé à un pirate de mer. Berlin envoya une canonnière, « Le Panthère », qui fit sauter le navire haitien: « La Crête à Pierrot » avec à son bord l’amiral. La presse internationale félicita les Allemands pour leur action punitive.`
      }
    ]
  },{
    date: '7-9',
    data: [
      {
        title: `1796. Election au Cap-Français de six nouveaux députés dont Sonthonax et Lavaux:`,
        description: `Ces députés devaient représenter Saint Domingue à l’Assemblée française. La première convocation de cette assemblée eut lieu le 20 mai 1797.`
      },{
        title: `1808. Rencontre des troupes de Pétion et de Christophe:`,
        description: `La scission du Nord et de l’Ouest devenait un fait accompli, mais chaque partie essayait de convaincre l’autre par les armes.
        L’armée de Pétion (Ouest) se rendant à Saint Marc pour assiéger la ville recontra, sur l’habitation Couyau, les troupes de Christophe (Nord). Ces dernières furent dispersées. Pétion parvint à assiéger la ville le 24 octobre et le siège dura jusqu’au milieu du mois de novembre.`
      }
    ]
  },{
    date: '8-9',
    data: [
      {
        title: `1902. Le corps mutilé et carbonisé de l’Amiral Killick est retrouvé:`,
        description: `On lui fit des funérailles grandioses malgré le refus du curé de la ville des Gonaives de lui donner l’absoute. Ce dernier assimile son geste à un acte de suicide.`
      }
    ]
  },{
    date: '9-9',
    data: [
      {
        title: `1941. Mgr François-Joseph Person, jusque là évêque coadjuteur des Cayes, devint l’ordinaire du diocèse:`,
        description: `Né le 20 Novembre 1895 à Plouider, France, nommé évêque le 26 Juin 1937, il remplaça Mgr. Jules-Victor-Marie Pichon qui démissionna de son poste le 1er septembre de la même année.`
      }
    ]
  },{
    date: '10-9',
    data: [
      {
        title: `2001. Loi interdisant les châtiments corporels contre les enfants:`,
        description: `2001. Loi interdisant les châtiments corporels contre les enfants`
      }
    ]
  },
  {
    date: '11-9',
    data: [
      {
        title: `1791: Concordat entre les blancs de la garde nationale de Port-au-Prince et les mulâtres libres:`,
        description: `Dans ce document signé à la Croix des Bouquets par les représentants des deux parties, à la suite des attaques répétées des mulâtres contres les intérêts des colons français, les blancs promirent de reconnaître et de respecter les droits des citoyens de couleur en leur promettant de ne point s’opposer à l’exécution des décrets nationaux qui leur étaient favorables.
        Les hommes de couleur de plusieurs paroisses de Saint Domingue, inspirés par ce concordat, obligèrent les colons de leur régions a suivre l’exemple de leurs congénères de l’Ouest.`
      },
      {
        title: `1988. Massacre à la chapelle de St Jean Bosco de Port-au-Prince:`,
        description: `Armé de brassard rouge, un commando fit irruption à la chapelle de Saint Jean Bosco de Port-au-Prince, administrée par les salésiens de Don Bosco, interrompit une messe célébrée alors par le père Jean-Bertrand Aristide et massacra des fidèles assistants à la célébration. La chapelle fut ensuite incendiée.`
      },
      {
        title: `1993. Assassinat d’Antoine Izméry:`,
        description: `Homme d’Affaires connu pour ses prises de position politiques et ses supports financiers au mouvement Lavalas, il fut assassiné en plein jour devant l’Eglise du Sacré-Coeur à Turgeau.`
      }
    ]
  },{
    date: '12-9',
    data: [
      {
        title: `1905. Décès au Cap-Haitien du général Monpoint Jeune:`,
        description: `Né au Cap-Haitien en 1830, Monpoint Jeune s’enrôla très tôt dans l’armée et gravit, grâce à son éducation et ses relations, rapidement les échelons dans la hiérarchie militaire. Commandant militaire de Port-au-Prince durant la présidence de Boisrond Canal, on le retrouva parmi les membres du gouvernement provisoire de 1989. Il fut, durant les présidences de Florvil Hyppolite et de Tirésias Simon Sam, ministre de la guerre et de la marine.`
      }
    ]
  },{
    date: '13-9',
    data: [
      {
        title: ``,
        description: ``
      }
    ]
  },{
    date: '14-9',
    data: [
      {
        title: `2003. Manifestation et contre-manifestation au Cap-Haitien:`,
        description: `Une manifestation, organisée par les partis de l’opposition (au pouvoir lavalas) et des groupes de la société civile qui réclamaient la démission du président Aristide, devait avoir lieu ce jour-là au Cap-Haïtien (département du Nord).
        Ce même jour et suivant le même parcours, des partisans du pouvoir organisèrent une contre-manifestation. Les affrontement entre les deux groupes fit une dizaine de blessés dans les deux camps, et un mort du côté des contre-manifestants.`
      }
    ]
  },{
    date: '15-9',
    data: [
      {
        title: `1893: Arrêté du président Hyppolite suspendant le conseil communal de Port-au-Prince:`,
        description: `Une commission chargée de gérer les intérêts de la commune jusqu’aux prochaines élections fut alors créée. Elle est composée des citoyens Mombrun Elie, Pierre Lafleur et Henri Augustin.`
      },
      {
        title: `1994. Le président américain, Bill clinton, menace les autorités militaires haitiennes:`,
        description: `Dans un discours télévisé, ce soir-là, le président américain Bill Clinton demanda officiellement aux autorités militaires, auteurs du coup d’état du 30 septembre 1991, de se retirer du pouvoir. Après avoir mis l’accent sur la situation alarmante des droits de l’homme en Haïti, il annonça l’intention des États-Unis d’utiliser la force, si nécessaire, pour mettre fin à cette situation.`
      },
      {
        title: `2018: Jean Henri Céant devint le 22è premier ministre d’Haïti`,
        description: `Désigné le 5 août par le président Jovenel Moise suite à la démission le 14 juillet précédent de Jack Guy Lafontant, victime de manifestations (6 au 8 juillet) contre l’augmentation des prix de l’essence. Jean-Henri Céant, le notaire, fut ratifié par les deux chambres après un marathon de 25 heures au Parlement.
        Au niveau de la chambre des députés, le Premier ministre désigné obtint 105 votes en faveur. 10 députés votèrent contre, et 6 se sont abstenus. Au niveau du Sénat, 21 sénateurs votèrent en faveur, 5 refusèrent de voter pour alors que 2 se sont abstenus.`
      }
    ]
  },{
    date: '16-9',
    data: [
      {
        title: ``,
        description: ``
      }
    ]
  },{
    date: '17-9',
    data: [
      {
        title: `1792. Les commissaires Polvelrel, Ailhaud et Sonthonax arrivèrent dans la rade du Cap:`,
        description: `Ces commissaires furent chargés de mettre en application la loi du 4 avril 1792 qui octroyèrent des droits politiques aux affranchis et aux hommes de couleur. Les esclaves noirs étaient exclus de la mesure en faveur des hommes de couleurs. Ainsi, trois jours après leur débarquement, ces commissaires affirmèrent formellement devant l’Assemblée coloniale de Saint Dominque leur volonté et celle de la France de maintenir l’esclavage dans la colonie. Devant faire face à l’opposition violente des blancs, ils se sont vus obliger de proclamer l’abolition de l’esclavage un an après.
        Ailhaud qui n’arrivait pas à s’adapter au climat de Saint Domingue retourna en France en octobre 1792. Il fut remplacé par Olivier Delpèche.`
      },{
        title: `1988. Coup d’état contre le général Henri Namphy:`,
        description: `Réalisé par les soldats de la Garde Présidentielle et de la base de l’armée, ce coup d’état donna suite à des opérations dedéchoucage dans l’administration publique et à une tentative de nettoyage au sein de l’armée.`
      },{
        title: `1994. Arrivée d’une délégation américaine chargée de négocier avec les auteurs du coup d’état de Septembre 1991`,
        description: `Composée de l’ancien président américain Jimmy Carter, de l’ancien chef des états-majeurs américains, général Colin Powell et du sénateur de la Georgie Sam Nunn, cette délégation avait pour mission de négocier in extremis une sortie honorable des militaires et bénéficiaires du coup d’état du 30 Septembre 1991, et de faciliter le retour sans heurts de Jean Bertrand Aristide en Haiti. A ce point, un débarquement américain en Haiti était inévitable.`
      }
    ]
  },{
    date: '18-9',
    data: [
      {
        title: `1811. Décès d’André Rigaud:`,
        description: `Né aux Cayes le 17 Janvier 1761, Rigaud, au moment de son décès, dirigeait le Conseil du Sud, le gouvernement du Département du Sud qui alimentait une guerre civile contre l’Ouest.`
      }
    ]
  },{
    date: '19-9',
    data: [
      {
        title: `1793. Les troupes anglaises, à l’invitation des colons français débarquèrent à Jérémie:`,
        description: `Les Français de la Grande Anse effrayés par la prépondérance sans cesse accrue des mulâtres des autres parties de la colonie de Saint Domingue et profitant de leur victoire sur Légion d’Égalité à Pestel (19 juin 1793) demandèrent la protection de l’Angleterre alors en guerre avec la France. Ils formalisèrent cette demande le 3 septembre 1793 à la Jamaïque. A leur débarquement à Jérémie, ils furent accueillis aux cris de “Vive le roi Georges”.
        Cet acte de trahison transforma Saint-Domingue en un des théâtres de la Guerre entre la France et l’Angleterre.`
      },{
        title: `1868: Tentative de scission du territoire par le général Nissage Saget:`,
        description: `Ce dernier, s’opposant à la politique du président Sylvain Salnave, fonda l’État Septentrional. Les département du Nord, du Nord-Ouest et de l’Artibonite firent partie de ce nouvel état belligérant. Leurs chef-lieux (Cap-Haitien, Port-de-Paix et Les Gonaïves) restèrent toutefois fidèles au gouvernement central.
        Deux jours plus tard, un autre général, Michel Domingue, créa son propre gouvernement dans le Sud.`
      },
      {
        title: `1959. Duvalier veut les pleins pouvoirs et en fit la demande au parlement:`,
        description: `Six senateurs s’opposa à cette requête. Ils furent immédiatement accusés de crime de haute trahison et de complot contre la sûrete de l’État. Cinq d’entre eux gagnèrent l’exil. Le pasteurYvon Emmanuel Moreau, membre du grand corps, arrêté ne laissa aucune trace.`
      },
      {
        title: `1994. Les Américains re-débarquent:`,
        description: `Débarquement d’un premier contingent Américain en Haiti  dans le cadre du mouvement de restauration de Jean Bertrand Aristide au pouvoir.Ce dernier élu le 16 décembre 1990 et assermenté le 7 février suivant, avait été renversé, à la suite d’un coup d’état militaire le 30 septembre 1991.`
      }
    ]
  },{
    date: '20-9',
    data: [
      {
        title: `1697. Première signature du traité de Ryswick:`,
        description: `Ce traité de paix entre la France, les Provinces-Unies, l’Angleterre et l’Espagne pour mettre fin à la guerre de la ligue d’Augsbourg. L’île d’Haïti, connue sous le nom d’Hispaniola, est partagée entre la France et l’Espagne. Une deuxième signature eut lieu le 30 octobre de la même année entre la France et l’empereur Léopold Ier.`
      },{
        title: `1734. Un incendie détruisit le ville du Cap-Haïtien:`,
        description: `Dans la nuit de ce fatidique jour, un incendie qui éclata dans le quartier de commerce et qui dura jusqu’au petit matin du 21 septembre détruisit la moitié de la ville causant des pertes de plusieurs millions.
        Les habitants (esclaves et colons) rivalisèrent de courage pour sauver la ville et ce, sans le secours des marins du vaisseau « La Charente » commandé par le capitaine de Vaudreuil et qui se trouvait mouiller dans la rade.`
      },{
        title: `1849. Promulgation de la Constitution impériale (second empire):`,
        description: `Cette constitution fait du président Faustin Soulouque Empereur d’Haiti sous le nom de Faustin 1er et de la République d’Haiti, l’empire d’Haiti. Elle proclama la « dignité impériale héréditaire dans la descendance directe, naturelle et légitime, de l’Empereur, de mâle en mâle, par ordre de progéniture », et la personne de l’Empereur « inviolable et sacrée, ». Elle donne une certaine constitutionnalité à la noblesse créée par la suite par l’empereur Faustin 1er…`
      }
    ]
  },{
    date: '21-9',
    data: [
      {
        title: `1811. Le général Jérôme Maximilien Borgella élu président du Conseil départemental du Sud:`,
        description: `Le général André Rigaud mort, « des intrigues commencèrent pour l’élection de son successeur » (Madiou, Thomas. Histoire d’Haiti. Tome V: 1811-1818. Port-au-Prince : Imprimerie Henri Deschamps, 1988; p. 32). 16 membres de l’assemblée départementale du Sud se réunirent dans la maison du défunt général pour élire ce successeur. Le général Borgella recueillit 12 voix.`
      },{
        title: `1868: Le général Michel Domingue fonda l’État Septentrional:`,
        description: `Ayant pour capitale, Les Cayes, cet état comprend toute la presqu’île du Sud. Moins épars que celui créé par Nissage dans le Nord, il est beaucoup mieux organisé avec un cabinet et un organe officiel « La Voix du Peuple ».
        Dans le cabinet on retrouve les personnalités suivantes:<br />
        . Septimus Rameau: Finances et commerce <br />
        . Linstant Pradines: Justice, cultes et instruction publique <br />
        . Pierre Momplaisir Pierre: La guerre <br />
        . David Fils-Aimé: Intérieur et agriculture.`
      },
      {
        title: `1977. Adhésion d’Haiti à la Convention Inter-Américaine sur les Droits de l’Homme:`,
        description: `Adoptée à San José du Costa Rica, le 22 novembre 1969, à la Conférence spécialisée inter-américaine sur les droits de l’homme, elle n’entra en vigueur que le 18 juillet 1978. Haiti la ratifia plus de 21 ans plus tard. Voici donc le texte de l’organisation hémisphérique entérinant cette ratification:
        « Le Président d´Haïti [Jean-Claude Duvalier], par l’instrument daté du 14 septembre 1977, a ratifié, conformément à l’article 93 de la Constitution nationale dudit État, la Convention américaine relative aux droits de l’homme, en promettant de la respecter sans aucune violation. »`
      }
    ]
  },{
    date: '22-9',
    data: [
      {
        title: `1793. Proclamation solennelle à Port-au-Prince de l’Acte de l’émancipation des esclaves:`,
        description: `Une initiative prise par le commissaire Sonthonax dans le Nord et qui avait demandé à Polvérel, son collègue de l’Ouest d’en faire autant. Ce dernier ne s’était pas montré trop enthousiaste à l’idée et aurait préféré une liberté progressive. Il dut toutefois se résigner à suivre les consignes de sonthonax après avoir assisté aux manifestations de mécontentement des esclaves de la ville de Saint Marc.`
      },{
        title: `1811. Prestatation de serment du général Jérôme Maximilien Borgella comme président du Conseil départemental du Sud:`,
        description: `Élu la veille par l’Assemblée départementale du Sud, le général Borgella prêta le serment d’usage avant de se rendre à l’église pour un Te Deum et s’adresse au peuple (« Discours » in Madiou, Thomas. Histoire d’Haiti. Tome V: 1811-1818. Port-au-Prince : Imprimerie Henri Deschamps, 1988; pp. 34-35) Borgella fera des ouvertures à Alexandre Pétion en vue de la réunification des Département de l’Ouest et du Sud.`
      },
      {
        title: `1883. Journée de violence à Port-au-Prince:`,
        description: `Une populace loyale au gouvernement de Lysius Salomon se disant révoltée par l’insurrection initiée à Miragoâne par des membres du parti Libéral de Boyer Bazelais incendia les quartiers habités par ces derniers et les maisons de commerce dont les gérants ou propriétaires affichaient une certaine sympathie à la cause des Libéraux. La violence accompagnée de pillage s’est poursuivi pendant deux jours.
        Les étrangers étrangers à tort ou à raison firent appel à leurs agents diplomatiques en Haïti pour réclamer des indemnités en leurs noms`
      },{
        title: `1957. Elections devant porter  le Dr. François Duvalier à la présidence:`,
        description: `Préparées par la junte militaire dirigée par le général Antonio Kébreau, ces élections présidentielles et législatives devinrent surtout, à la suite du retrait de Clément Jumelle, ancien ministre des finances du gouvernement de Magloire, un affrontement entre les deux candidats présidentiels représentant les deux sphères sociales d’Haiti: le Dr. François Duvalier, un médecin, ancien ministre du travail du président Estimé, et Louis Déjoie, un industriel et ancien sénateur de la République. Duvalier se vit attribuer la victoire quelques jours plus tard.
        Les Haitiens âgés d’au moins 21 ans étaient également appelés à choisir les 21 sénateurs et 37 députés qui devaient former l’assemblée nationale.`
      },{
        title: `1998. Le cyclone Georges frappe Haiti:`,
        description: `Après le passage de ce cyclone, un rapport de l’Office de la Protection Civile fit mention de  27 décès, 29 blessés, et 9 personnes portées disparues. Les dégâts provinrent surtout des inondations.`
      }
    ]
  },
  {
    date: '23-9',
    data: [
      {
        title: `1831. Loi consacrant la fondation de Pétion-Ville:`,
        description: `Le projet de faire de cette ville, située au flanc des montagnes de la Coupe et dédiée au président Alexandre Pétion, la capitale d’Haiti n’a jamais été réalisé. Pendant longtemps, Pétion-Ville fut une sorte de ville dortoir; aujourd’hui, elle s’impose par elle-même devenant un centre d’affaires très florissant.`
      },{
        title: `1887. A tremblement de terre secoua le région septentrionale d’Haïti, détruisant l’église de port-de-Paix:`,
        description: `Ce tremblement de terre occasionna des dégâts dans toutes les zones de la région septentrionale, du Môle Saint Nicolas à Fort-Liberté
        Dans un communiqué publié dans le Moniteur du 6 octobre 1887, le gouvernement du président Salomon fit état de maisons « lézardées » à Port-de Paix, de « 126 maisons détruites » au Môle Saint Nicolas, de « pertes » au Limbé et à la Grande Rivière du Nord.`
      }
    ]
  },
  {
    date: '24-9',
    data: [
      {
        title: `1994. Premier incident grave depuis le deuxième débarquement des Américains en Haiti, le 19 Septembre 1994:`,
        description: `Dix Haïtiens sont tués par des Marines au Cap-Haïtien. On prétend qu’ils étaient armés.`
      },{
        title: `1996. Haiti signe le Traité d’interdiction complète des essais nucléaires`,
        description: `Haiti fut l’un des premiers membres à signer ce traité adopté à la 50ème session des Nations-Unies, le 10 septembre 1996 par sa résolution A-RES-50-245.`
      }
    ]
  },
  {
    date: '25-9',
    data: [
      {
        title: `1860. Le Concordat est contresigné par le Souverain Pontife, Pie IX:`,
        description: `Troisième projet finalement approuvé par les deux parties (le gouvernement d’Haiti et le Saint siège), le Concordat de 1860 fut signé respectivmenet par Pierre Faubert et le cardinal Giacomo Antonelli. Le président Geffrard le contresigna le 10 mai et le Sénat le ratifia le 1er août de la même année.
        Avec la signature du Pontife catholique, il entra officiellement en fonction et ne sera lègèrement altéré qu’en 1984 avec la révision de l’article 4 qui accordait, jusque là, au président d’Haiti le droit exclusif de nommer les évêques et archevêques.`
      }
    ]
  },
  {
    date: '26-9',
    data: [
      {
        title: `1962. Naissance de Mgr. Pierre-André Dumas, évêque de l’Anse à Veau-Miragoâne:`,
        description: `Né à Saint Jean du Sud, dans le département du Sud, Mgr. Dumas fut ordonné prêtre le 26 mai, 1991 et élevé à la dignité épiscopale le 10 décembre, 2002 comme évêque auxiliaire de Port-au-Prince. Il fut transféré au nouveau diocèse Anse à Veau-Miragoâne le 13 juillet 2008.`
      }
    ]
  },
  {
    date: '27-9',
    data: [
      {
        title: `1843: Un arrêté du gouvernement provisoire dirigé par Hérard ferme les ports de la partie orientale de l’île:`,
        description: `« Cette mesure devait provoquer une vive irritation et une aigreur générale au milieu de la population hispano-haitiennes » de la partie de l’Est.`
      },{
        title: `1991. Discours du président Jean-Bertrand Aristide faisant l’apologie du Pè Lebrun`,
        description: `Revenant de New York où il avait prononcé un discours devant la 46ème Assemblée Générale, le président exhorta ses partisans à être vigilants et utilisant le cas échéant « l’instrument », se référant au « Pè Lebrun« .`
      }
    ]
  },
  {
    date: '28-9',
    data: [
      {
        title: `1888. Le général Seïde Thélémaque tué au cours d’une sanglante échauffourée:`,
        description: `Commandant de l’arrondissement du Cap-Haitien et chef d’un mouvement insurrectionnel (5 août 1888), à l’origine dde la démission du président Salomon, le général Seide Thélémaque s’était rendu à Port-au-Prince pour recueillir ses lauriers présidentiels selon la coutume de l’époque. Il dut cependant affronter le général François Légitime qui, lui aussi, se porta candidat. Leurs partisans s’affrontèrent et le général Thélémaque perdit la vie lors d’une sanglante échauffourée. Légitime devint alors président.`
      }
    ]
  },
  {
    date: '29-9',
    data: [
      {
        title: `1688. Publication du Code Noir à Léogâne:`,
        description: `Promulgué en Mars 1685, le Code Noir est un recueil de 60 articles rédigés par Colbert. Le code noir légalise l’esclavage dans les colonies françaises, assimile l’esclave à un bien producteur et assure sa protection comme tel.`
      }
    ]
  },
  {
    date: '30-9',
    data: [
      {
        title: `1683. Expulsion des juifs de Saint Domingue:`,
        description: `Louis XIV s’inclina alors devant la volonté des premiers membres du clergé catholique dans la colonie qui voyaient dans la présence des juifs un obstacle à l’épanouissement du catholicisme en Haiti.`
      },{
        title: `1991. Coup d’état contre le président Jean-Bertrand Aristide:`,
        description: `Mouvement de protestation contre le gouvernement de Jean Bertrand Aristide entame par une unité des Forces Armées d’Haiti le 29 Septembre. Propagé plus tard dans d’autres unités, il fut récupéré par la hiérarchie militaire. Le président sera arrêté et expulsé. Il séjourna dans un premier temps au Venezuela, et s’établit dans un second temps à Washington ou il organisa la résistance avec la loyauté de toutes les missions diplomatiques haïtiennes à l’étranger. Un document publié le 20 Octobre 1991 par les Forces Armées parla de redressement à la « derive démocratique » pour justifier le coup.`
      }
    ]
  }
]