import React, { useEffect, useState } from 'react';
import Preloader from './../components/Preloader';
import HeroSection from './../components/HeroSection';
import Title from '../components/Title';
import VideoFrame from '../components/VideoFrame';
import DateEvent from '../components/DateEvent';
import ContentAnimateur from '../components/ContentAnimateur';
import SideBar from '../components/SideBar';
import Wave from './../images/wave.svg';
import Breadcrumb from '../components/Breadcrumb';
import PersonalSection from '../components/PersonalSection';

const Animateur = ()=>{
  const [isLoad, setIsload] = useState(true);
  if(!isLoad){ return <Preloader /> }

  return (<section className="main-content">
  <div className="container-xl">
  <Breadcrumb title={"Nos Animateurs"} />
    <div className="row gy-4">
    <div className="col-lg-8">

      <div className="page-content bordered rounded padding-30">
        <div class="row gy-4">
        {
            ([ 
                {
                    fullname: 'Dr. Roseman Aspilaire', 
                    media_profil: require('./../images/rosemon-aspi.jpeg'),
                    description: `Economiste/statisticien avec une longue expérience dans la création de base donnée, dans la
                    réalisation de sondages d’opinion lourde...`
                },
                {
                    fullname: 'Mildrède Béliard', 
                    media_profil: require('./../images/midl-belliard.jpeg'),
                    description: `Je m'appelle Mildrède Béliard et j'en ai marre de ces éternels fauteurs de trouble que notre presse nomme pompeusement "leaders politiques"!`
                }
            ]).map((item, pos)=>{
                return <PersonalSection content={item} key={`ani-${pos}`}/>
            })
        }
        </div>
        <hr className="my-4" />
      </div>
    </div>

    <div className="col-lg-4">
      <SideBar />
    </div>
  </div>
</div>
</section>);
}


export default Animateur;
