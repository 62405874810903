module.exports = [
  [
    {
      title: `5h00 Am: Thème officiel d'ouverture`,
      description: ``
    },{
      title: `5h05-5h10: Salut au soleil`,
      description: ``
    },{
      title: `5h10-5h20 Am: Météo`,
      description: ``
    },{
      title: `5h20-6h00 Am: Horoscope & Éphéméride`,
      description: ``
    },{
      title: `6h00-6h45 Am: La vie dans les rues`,
      description: ``
    },{
      title: `6h45h-7h00 Am: Brèves de l’actualité`,
      description: ``
    },{
      title: `7h00-8h00 Am: Détente musicale`,
      description: ``
    },{
      title: `8h00-8h05 Am: Salut au drapeau`,
      description: ``
    },{
      title: `8h05-8h15 Am: Petites annonces`,
      description: ``
    },{
      title: `8h15-10h00 Am: Variétés musicales`,
      description: ``
    },{
      title: `10h00-11h00 Am: Anndan lakay`,
      description: ``
    },{
      title: `11h00-1h00 Pm: Musiques d’Haïti`,
      description: ``
    },{
      title: `1h00-2h00 Pm: Nouvelles de la République`,
      description: ``
    },{
      title: `2h00-3h00 Pm: Sons d’Afrique et des Antilles`,
      description: ``
    },{
      title: `3h00-3h45 Pm: Actualité sportive`,
      description: ``
    },{
      title: `3h45-4h15Pm: Leçon d’histoire`,
      description: ``
    },{
      title: `4h15-4h30 Pm: Cours du soir`,
      description: ``
    },{
      title: `4h30-6h00 Pm: Tanbou frape`,
      description: ``
    },{
      title: `6h00-7h00 Pm: La Science pour tous`,
      description: ``
    },{
      title: `7h00-7h30 Pm: Plage musicale`,
      description: ``
    },{
      title: `7h30-8h00 Pm: VAR`,
      description: ``
    },{
      title: `8h00-9h00 Pm: Invité du jour`,
      description: ``
    },{
      title: `9h00-11h00 Pm: Parlons d’amour`,
      description: ``
    },{
      title: `11h00 PM-Minuit: Café Lounge`,
      description: ``
    },{
      title: `Minuit-Minuit 05: Chanje katye`,
      description: ``
    },{
      title: `Minuit 05-5h00 Am: Variétés musicales`,
      description: ``
    }
  ],[
    {
      title: `5h00 Am: Thème officiel d'ouverture`,
      description: ``
    },{
      title: `5h05-5h10: Salut au soleil`,
      description: ``
    },{
      title: `5h10-5h20 Am: Météo`,
      description: ``
    },{
      title: `5h20-6h00 Am: Horoscope & Éphéméride`,
      description: ``
    },{
      title: `6h00-6h45 Am: La vie dans les rues`,
      description: ``
    },{
      title: `6h45h-7h00 Am: Brèves de l’actualité`,
      description: ``
    },{
      title: `7h00-8h00 Am: Détente musicale`,
      description: ``
    },{
      title: `8h00-8h05 Am: Salut au drapeau`,
      description: ``
    },{
      title: `8h05-8h15 Am: Petites annonces`,
      description: ``
    },{
      title: `8h15-10h00 Am: Variétés musicales`,
      description: ``
    },{
      title: `10h00-11h00 Am: Anndan lakay`,
      description: ``
    },{
      title: `11h00-1h00 Pm: Musiques d’Haïti`,
      description: ``
    },{
      title: `1h00-2h00 Pm: Nouvelles de la République`,
      description: ``
    },{
      title: `2h00-3h00 Pm: Sons d’Afrique et des Antilles`,
      description: ``
    },{
      title: `3h00-3h45 Pm: Actualité sportive`,
      description: ``
    },{
      title: `3h45-4h15Pm: Leçon d’histoire`,
      description: ``
    },{
      title: `4h15-4h30 Pm: Cours du soir`,
      description: ``
    },{
      title: `4h30-6h00 Pm: Tanbou frape`,
      description: ``
    },{
      title: `6h00-7h00 Pm: La Science pour tous`,
      description: ``
    },{
      title: `7h00-7h30 Pm: Plage musicale`,
      description: ``
    },{
      title: `7h30-8h00 Pm: VAR`,
      description: ``
    },{
      title: `8h00-9h00 Pm: Invité du jour`,
      description: ``
    },{
      title: `9h00-11h00 Pm: Parlons d’amour`,
      description: ``
    },{
      title: `11h00 PM-Minuit: Café Lounge`,
      description: ``
    },{
      title: `Minuit-Minuit 05: Chanje katye`,
      description: ``
    },{
      title: `Minuit 05-5h00 Am: Variétés musicales`,
      description: ``
    }
  ],[
    {
      title: `5h00 Am: Thème officiel d'ouverture`,
      description: ``
    },{
      title: `5h05-5h10: Salut au soleil`,
      description: ``
    },{
      title: `5h10-5h20 Am: Météo`,
      description: ``
    },{
      title: `5h20-6h00 Am: Horoscope & Éphéméride`,
      description: ``
    },{
      title: `6h00-6h45 Am: La vie dans les rues`,
      description: ``
    },{
      title: `6h45h-7h00 Am: Brèves de l’actualité`,
      description: ``
    },{
      title: `7h00-8h00 Am: Détente musicale`,
      description: ``
    },{
      title: `8h00-8h05 Am: Salut au drapeau`,
      description: ``
    },{
      title: `8h05-8h15 Am: Petites annonces`,
      description: ``
    },{
      title: `8h15-10h00 Am: Variétés musicales`,
      description: ``
    },{
      title: `10h00-11h00 Am: Anndan lakay`,
      description: ``
    },{
      title: `11h00-1h00 Pm: Musiques d’Haïti`,
      description: ``
    },{
      title: `1h00-2h00 Pm: Nouvelles de la République`,
      description: ``
    },{
      title: `2h00-3h00 Pm: Sons d’Afrique et des Antilles`,
      description: ``
    },{
      title: `3h00-3h45 Pm: Actualité sportive`,
      description: ``
    },{
      title: `3h45-4h15Pm: Leçon d’histoire`,
      description: ``
    },{
      title: `4h15-4h30 Pm: Cours du soir`,
      description: ``
    },{
      title: `4h30-6h00 Pm: Tanbou frape`,
      description: ``
    },{
      title: `6h00-7h00 Pm: La Science pour tous`,
      description: ``
    },{
      title: `7h00-7h30 Pm: Plage musicale`,
      description: ``
    },{
      title: `7h30-8h00 Pm: VAR`,
      description: ``
    },{
      title: `8h00-9h00 Pm: Invité du jour`,
      description: ``
    },{
      title: `9h00-11h00 Pm: Parlons d’amour`,
      description: ``
    },{
      title: `11h00 PM-Minuit: Café Lounge`,
      description: ``
    },{
      title: `Minuit-Minuit 05: Chanje katye`,
      description: ``
    },{
      title: `Minuit 05-5h00 Am: Variétés musicales`,
      description: ``
    }
  ],[
    {
      title: `5h00 Am: Thème officiel d'ouverture`,
      description: ``
    },{
      title: `5h05-5h10: Salut au soleil`,
      description: ``
    },{
      title: `5h10-5h20 Am: Météo`,
      description: ``
    },{
      title: `5h20-6h00 Am: Horoscope & Éphéméride`,
      description: ``
    },{
      title: `6h00-6h45 Am: La vie dans les rues`,
      description: ``
    },{
      title: `6h45h-7h00 Am: Brèves de l’actualité`,
      description: ``
    },{
      title: `7h00-8h00 Am: Détente musicale`,
      description: ``
    },{
      title: `8h00-8h05 Am: Salut au drapeau`,
      description: ``
    },{
      title: `8h05-8h15 Am: Petites annonces`,
      description: ``
    },{
      title: `8h15-10h00 Am: Variétés musicales`,
      description: ``
    },{
      title: `10h00-11h00 Am: Anndan lakay`,
      description: ``
    },{
      title: `11h00-1h00 Pm: Musiques d’Haïti`,
      description: ``
    },{
      title: `1h00-2h00 Pm: Nouvelles de la République`,
      description: ``
    },{
      title: `2h00-3h00 Pm: Sons d’Afrique et des Antilles`,
      description: ``
    },{
      title: `3h00-3h45 Pm: Actualité sportive`,
      description: ``
    },{
      title: `3h45-4h15Pm: Leçon d’histoire`,
      description: ``
    },{
      title: `4h15-4h30 Pm: Cours du soir`,
      description: ``
    },{
      title: `4h30-6h00 Pm: Tanbou frape`,
      description: ``
    },{
      title: `6h00-7h00 Pm: La Science pour tous`,
      description: ``
    },{
      title: `7h00-7h30 Pm: Plage musicale`,
      description: ``
    },{
      title: `7h30-8h00 Pm: VAR`,
      description: ``
    },{
      title: `8h00-9h00 Pm: Invité du jour`,
      description: ``
    },{
      title: `9h00-11h00 Pm: Parlons d’amour`,
      description: ``
    },{
      title: `11h00 PM-Minuit: Café Lounge`,
      description: ``
    },{
      title: `Minuit-Minuit 05: Chanje katye`,
      description: ``
    },{
      title: `Minuit 05-5h00 Am: Variétés musicales`,
      description: ``
    }
  ],[
    {
      title: `5h00 Am: Thème officiel d'ouverture`,
      description: ``
    },{
      title: `5h05-5h10: Salut au soleil`,
      description: ``
    },{
      title: `5h10-5h20 Am: Météo`,
      description: ``
    },{
      title: `5h20-6h00 Am: Horoscope & Éphéméride`,
      description: ``
    },{
      title: `6h00-6h45 Am: La vie dans les rues`,
      description: ``
    },{
      title: `6h45h-7h00 Am: Brèves de l’actualité`,
      description: ``
    },{
      title: `7h00-8h00 Am: Détente musicale`,
      description: ``
    },{
      title: `8h00-8h05 Am: Salut au drapeau`,
      description: ``
    },{
      title: `8h05-8h15 Am: Petites annonces`,
      description: ``
    },{
      title: `8h15-10h00 Am: Variétés musicales`,
      description: ``
    },{
      title: `10h00-11h00 Am: Anndan lakay`,
      description: ``
    },{
      title: `11h00-1h00 Pm: Musiques d’Haïti`,
      description: ``
    },{
      title: `1h00-2h00 Pm: Nouvelles de la République`,
      description: ``
    },{
      title: `2h00-3h00 Pm: Sons d’Afrique et des Antilles`,
      description: ``
    },{
      title: `3h00-3h45 Pm: Actualité sportive`,
      description: ``
    },{
      title: `3h45-4h15Pm: Leçon d’histoire`,
      description: ``
    },{
      title: `4h15-4h30 Pm: Cours du soir`,
      description: ``
    },{
      title: `4h30-6h00 Pm: Tanbou frape`,
      description: ``
    },{
      title: `6h00-7h00 Pm: La Science pour tous`,
      description: ``
    },{
      title: `7h00-7h30 Pm: Plage musicale`,
      description: ``
    },{
      title: `7h30-8h00 Pm: VAR`,
      description: ``
    },{
      title: `8h00-9h00 Pm: Invité du jour`,
      description: ``
    },{
      title: `9h00-11h00 Pm: Parlons d’amour`,
      description: ``
    },{
      title: `11h00 PM-Minuit: Café Lounge`,
      description: ``
    },{
      title: `Minuit-Minuit 05: Chanje katye`,
      description: ``
    },{
      title: `Minuit 05-5h00 Am: Variétés musicales`,
      description: ``
    }
  ],[
    {
      title: `5h00 Am: Thème officiel d'ouverture`,
      description: ``
    },{
      title: `5h05-5h10: Salut au soleil`,
      description: ``
    },{
      title: `5h10-5h20 Am: Météo`,
      description: ``
    },{
      title: `5h20-6h00 Am: Horoscope & Éphéméride`,
      description: ``
    },{
      title: `6h00-6h45 Am: La vie dans les rues`,
      description: ``
    },{
      title: `6h45h-7h00 Am: Brèves de l’actualité`,
      description: ``
    },{
      title: `7h00-8h00 Am: Détente musicale`,
      description: ``
    },{
      title: `8h00-8h05 Am: Salut au drapeau`,
      description: ``
    },{
      title: `8h05-8h15 Am: Petites annonces`,
      description: ``
    },{
      title: `8h15-10h00 Am: Variétés musicales`,
      description: ``
    },{
      title: `10h00-11h00 Am: Anndan lakay`,
      description: ``
    },{
      title: `11h00-1h00 Pm: Musiques d’Haïti`,
      description: ``
    },{
      title: `1h00-2h00 Pm: Nouvelles de la République`,
      description: ``
    },{
      title: `2h00-3h00 Pm: Sons d’Afrique et des Antilles`,
      description: ``
    },{
      title: `3h00-3h45 Pm: Actualité sportive`,
      description: ``
    },{
      title: `3h45-4h15Pm: Leçon d’histoire`,
      description: ``
    },{
      title: `4h15-4h30 Pm: Cours du soir`,
      description: ``
    },{
      title: `4h30-6h00 Pm: Tanbou frape`,
      description: ``
    },{
      title: `6h00-7h00 Pm: La Science pour tous`,
      description: ``
    },{
      title: `7h00-7h30 Pm: Plage musicale`,
      description: ``
    },{
      title: `7h30-8h00 Pm: VAR`,
      description: ``
    },{
      title: `8h00-9h00 Pm: Invité du jour`,
      description: ``
    },{
      title: `9h00-11h00 Pm: Parlons d’amour`,
      description: ``
    },{
      title: `11h00 PM-Minuit: Café Lounge`,
      description: ``
    },{
      title: `Minuit-Minuit 05: Chanje katye`,
      description: ``
    },{
      title: `Minuit 05-5h00 Am: Variétés musicales`,
      description: ``
    }
  ],[
    {
      title: `5h00 Am: Thème officiel d'ouverture`,
      description: ``
    },{
      title: `5h05-5h10: Salut au soleil`,
      description: ``
    },{
      title: `5h10-5h20 Am: Météo`,
      description: ``
    },{
      title: `5h20-6h00 Am: Horoscope & Éphéméride`,
      description: ``
    },{
      title: `6h00-6h45 Am: La vie dans les rues`,
      description: ``
    },{
      title: `6h45h-7h00 Am: Brèves de l’actualité`,
      description: ``
    },{
      title: `7h00-8h00 Am: Détente musicale`,
      description: ``
    },{
      title: `8h00-8h05 Am: Salut au drapeau`,
      description: ``
    },{
      title: `8h05-8h15 Am: Petites annonces`,
      description: ``
    },{
      title: `8h15-10h00 Am: Variétés musicales`,
      description: ``
    },{
      title: `10h00-11h00 Am: Anndan lakay`,
      description: ``
    },{
      title: `11h00-1h00 Pm: Musiques d’Haïti`,
      description: ``
    },{
      title: `1h00-2h00 Pm: Nouvelles de la République`,
      description: ``
    },{
      title: `2h00-3h00 Pm: Sons d’Afrique et des Antilles`,
      description: ``
    },{
      title: `3h00-3h45 Pm: Actualité sportive`,
      description: ``
    },{
      title: `3h45-4h15Pm: Leçon d’histoire`,
      description: ``
    },{
      title: `4h15-4h30 Pm: Cours du soir`,
      description: ``
    },{
      title: `4h30-6h00 Pm: Tanbou frape`,
      description: ``
    },{
      title: `6h00-7h00 Pm: La Science pour tous`,
      description: ``
    },{
      title: `7h00-7h30 Pm: Plage musicale`,
      description: ``
    },{
      title: `7h30-8h00 Pm: VAR`,
      description: ``
    },{
      title: `8h00-9h00 Pm: Invité du jour`,
      description: ``
    },{
      title: `9h00-11h00 Pm: Parlons d’amour`,
      description: ``
    },{
      title: `11h00 PM-Minuit: Café Lounge`,
      description: ``
    },{
      title: `Minuit-Minuit 05: Chanje katye`,
      description: ``
    },{
      title: `Minuit 05-5h00 Am: Variétés musicales`,
      description: ``
    }
  ]
]