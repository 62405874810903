import React, { useEffect, useState } from 'react';

const PersonalSection = ({content})=>{
  return (<div className="col-sm-6">
  <div className="post post-grid rounded bordered">
      <div className="thumb top-rounded">
          <a href="category.html" className="category-badge position-absolute">&emsp;</a>
          <a href="#blog-single.html">
              <div className="inner">
                  <img src={content.media_profil} alt="post-title" />
              </div>
          </a>
      </div>
      <div className="details">
          <h5 className="post-title mb-3 mt-3"><a href="#blog-single.html">{content.fullname}</a></h5>
          <p className="excerpt mb-0">{content.description}</p>
      </div>
      <div className="post-bottom clearfix d-flex align-items-center">
          <div className="social-share me-auto">
            <a className="" href={"#/"}><i className="fab fa-facebook-f"></i></a>
          </div>
          
          <div className="float-end">
            <a href="#/"><i className="fab fa-twitter"></i></a>
          </div>
      </div>
  </div>
</div>);
}


export default PersonalSection;
