import React, { useEffect, useState } from 'react';

const NotFound = ()=>{
  const [] = useState();
  return (<section className="main-content">
  <div className="container-xl">
    <div className="row gy-4">
      
    </div>
  </div>
</section>);
}


export default NotFound;
