import React, { useEffect, useState } from 'react';

const PlayerBar = ()=>{
  const [] = useState();
  return (<div id='player-bar' style={{
    position: "fixed", bottom: 0, left:0, width: "100%",  textAlign: "center", padding: "12px 0", margin:0,  background: "rgba(244, 244, 244, 0.9)",  color: "#919191",  font: "14px arial, sans-serif"
  }}>
	<div style={{
    display:"inline-block", width:"78%", margin:0, fontFamily: "Arial"
  }}>
		
		<div className="ads-horizontal text-md-center">
			<span className="ads-title">- En direct -</span>
			<iframe width="100%" height="300" title="radio"  style={{width: "100%", height:"100px"}} scrolling="no" frameBorder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1324761286&color=%23ff5500&auto_play=true&hide_related=true&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>
      <div style={{
        fontSize: "10px", color: "#cccccc", lineBreak: "anywhere", wordBreak: "normal", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", fontFamily: "Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif", fontWeight: 100
      }}><a href="https://soundcloud.com/radio-rezistans-fm" title="Radio Rezistans FM" target="_blank" style={{color: "#cccccc", textDecoration: "none"}} rel="noreferrer">Radio Rezistans FM</a> · <a href="https://soundcloud.com/radio-rezistans-fm/ti-limye-sou-listwa-emission-bwa-kayiman" title="Ti Limyè Sou Listwa - Émission Bwa Kayiman" target="_blank" style={{color: "#cccccc", textDecoration: "none"}} rel="noreferrer">Ti Limyè Sou Listwa - Émission Bwa Kayiman</a></div>
		</div>
	</div>
	<div style={{width: "20%"}}></div>
</div>);
}


export default PlayerBar;
