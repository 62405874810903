import React, { useEffect, useState } from 'react';

const Breadcrumb = ({title})=>{
  return (<section className="page-header rounded mb-2">
  <div className="container-xl">
      <div className="text-center">
          <h1 className="mt-0 mb-0">{title}</h1>
          <nav aria-label="breadcrumb">
              <ol className="breadcrumb justify-content-center mb-0">
                  <li className="breadcrumb-item"><a href="./">Accueil</a></li>
                  <li className="breadcrumb-item active" aria-current="page">{title}</li>
              </ol>
          </nav>
      </div>
  </div>
</section>);
}


export default Breadcrumb;
